const _temp0 = require("../../../ring-of-titans/Assets/Data/Spells/TemplateSpellData.asset");
const _temp1 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/Bash.asset");
const _temp2 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/CloudForm.asset");
const _temp3 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/CloudFormCancel.asset");
const _temp4 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/Cultivation.asset");
const _temp5 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/DustStorm.asset");
const _temp6 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/LightningBolt.asset");
const _temp7 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/LightningFire.asset");
const _temp8 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/LightningForm.asset");
const _temp9 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/MassRevitalization.asset");
const _temp10 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/NatureForm.asset");
const _temp11 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/RefreshingLeaves.asset");
const _temp12 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/Regeneration.asset");
const _temp13 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/Revitalize.asset");
const _temp14 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/Root.asset");
const _temp15 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/SwiftNourishment.asset");
const _temp16 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/SwiftRenewal.asset");
const _temp17 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/ThickSkin.asset");
const _temp18 = require("../../../ring-of-titans/Assets/Data/Spells/Airion/ThunderFire.asset");

const _temp19 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/BlindingLight.asset");
const _temp20 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/CorporealProtection.asset");
const _temp21 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/DivineCalling.asset");
const _temp22 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/HolyDeflection.asset");
const _temp23 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/HolyDeflectionShortCD.asset");
const _temp24 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/HolyStrike.asset");
const _temp25 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/Hope.asset");
const _temp26 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/InstantMysticalBrilliance.asset");
const _temp27 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/InstantRighteousCleansing.asset");
const _temp28 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/MaceSwing.asset");
const _temp29 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/MysticalBrilliance.asset");
const _temp30 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/RighteousCleansing.asset");
const _temp31 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/RighteousDefense.asset");
const _temp32 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/RighteousDefenseCancel.asset");
const _temp33 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/RighteousFlash.asset");
const _temp34 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/RighteousPrivilege.asset");
const _temp35 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/SpiritualBrilliance.asset");
const _temp36 = require("../../../ring-of-titans/Assets/Data/Spells/Anthriel/VirtuousBonding.asset");

const _temp37 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/ChainsOfIce.asset");
const _temp38 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/CrystalDetonation.asset");
const _temp39 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/FieryInfliction.asset");
const _temp40 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/Frostlance.asset");
const _temp41 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/FrostPortal.asset");
const _temp42 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/FrostShield.asset");
const _temp43 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/FrozenReversal.asset");
const _temp44 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/GlacialEncapsulation.asset");
const _temp45 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/GlacialEncapsulationCancel.asset");
const _temp46 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/IcyBindings.asset");
const _temp47 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/IcyExplosion.asset");
const _temp48 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/IcyShards.asset");
const _temp49 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/InnerFrost.asset");
const _temp50 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/InstantFrostlance.asset");
const _temp51 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/Invisibility.asset");
const _temp52 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/Penguinify.asset");
const _temp53 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/PenguinifyShort.asset");
const _temp54 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/Pyrolance.asset");
const _temp55 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/PyrolanceInstant.asset");
const _temp56 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/SharpIce.asset");
const _temp57 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/SlowFrostlance.asset");
const _temp58 = require("../../../ring-of-titans/Assets/Data/Spells/Azora/Spellscatter.asset");

const _temp59 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/InnerRage.asset");
const _temp60 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Leap.asset");
const _temp61 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Shockwave.asset");
const _temp62 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/ShockwaveShortCD.asset");
const _temp63 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Slash1.asset");
const _temp64 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Slash2.asset");
const _temp65 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Slash3.asset");
const _temp66 = require("../../../ring-of-titans/Assets/Data/Spells/BlademasterTarcza/Slice.asset");

const _temp67 = require("../../../ring-of-titans/Assets/Data/Spells/BruiserVolen/Dash.asset");
const _temp68 = require("../../../ring-of-titans/Assets/Data/Spells/BruiserVolen/ScatteredAttacks.asset");
const _temp69 = require("../../../ring-of-titans/Assets/Data/Spells/BruiserVolen/Slam.asset");

const _temp70 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkDamage.asset");
const _temp71 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkDot.asset");
const _temp72 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkHeal.asset");
const _temp73 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkMending.asset");
const _temp74 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkShield.asset");
const _temp75 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/DarkSuppression.asset");
const _temp76 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/InstantHeal.asset");
const _temp77 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/InstantHealImproved.asset");
const _temp78 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/SoulSiphon.asset");
const _temp79 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/SoulSwap.asset");
const _temp80 = require("../../../ring-of-titans/Assets/Data/Spells/DarkKanna/SpiritSector.asset");

const _temp81 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/CrystalBlast.asset");
const _temp82 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/CrystalBreath.asset");
const _temp83 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/CrystalHaste.asset");
const _temp84 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/CrystalShield.asset");
const _temp85 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FireCrystal1.asset");
const _temp86 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FireCrystal2.asset");
const _temp87 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FireCrystal3.asset");
const _temp88 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FireCrystal4.asset");
const _temp89 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FrostCrystal1.asset");
const _temp90 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FrostCrystal2.asset");
const _temp91 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FrostCrystal3.asset");
const _temp92 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/FrostCrystal4.asset");
const _temp93 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/InstantPenguinify.asset");
const _temp94 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/MendingCrystals.asset");
const _temp95 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/PureCrystal1.asset");
const _temp96 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/PureCrystal2.asset");
const _temp97 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/PureCrystal3.asset");
const _temp98 = require("../../../ring-of-titans/Assets/Data/Spells/CrystalAzora/PureCrystal4.asset");

const _temp99 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/LongIncap.asset");
const _temp100 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/PestulantDevastation.asset");
const _temp101 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/PestulantDevastationImproved.asset");
const _temp102 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/ResidualImplosion.asset");
const _temp103 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/Shadowspike.asset");
const _temp104 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/VerdantPyre.asset");
const _temp105 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/VerdantPyreInstant.asset");
const _temp106 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/VirulentEruption.asset");
const _temp107 = require("../../../ring-of-titans/Assets/Data/Spells/EradicatorMalrah/VirulentOrb.asset");

const _temp108 = require("../../../ring-of-titans/Assets/Data/Spells/General/Absorption.asset");
const _temp109 = require("../../../ring-of-titans/Assets/Data/Spells/General/Bandage.asset");
const _temp110 = require("../../../ring-of-titans/Assets/Data/Spells/General/ChainBreaker.asset");
const _temp111 = require("../../../ring-of-titans/Assets/Data/Spells/General/Dismount.asset");
const _temp112 = require("../../../ring-of-titans/Assets/Data/Spells/General/EmoteDance.asset");
const _temp113 = require("../../../ring-of-titans/Assets/Data/Spells/General/EnhancedPower.asset");
const _temp114 = require("../../../ring-of-titans/Assets/Data/Spells/General/EnhancedResilience.asset");
const _temp115 = require("../../../ring-of-titans/Assets/Data/Spells/General/Jump.asset");
const _temp116 = require("../../../ring-of-titans/Assets/Data/Spells/General/MasterofBattle.asset");
const _temp117 = require("../../../ring-of-titans/Assets/Data/Spells/General/Meditation.asset");
const _temp118 = require("../../../ring-of-titans/Assets/Data/Spells/General/Mount.asset");

const _temp119 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/BigHolySmite.asset");
const _temp120 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/BlessedLight.asset");
const _temp121 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/BlessedLightImp.asset");
const _temp122 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/Dispel.asset");
const _temp123 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/DivineIntervention.asset");
const _temp124 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/DivineLight.asset");
const _temp125 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/ExplosionOfLight.asset");
const _temp126 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/Fear.asset");
const _temp127 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/HolyFlash.asset");
const _temp128 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/HolyFortitude.asset");
const _temp129 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/PowerBurn.asset");
const _temp130 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/RighteousPrayer.asset");
const _temp131 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/RighteousShield.asset");
const _temp132 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/SacredSelection.asset");
const _temp133 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/ShackleOfLight.asset");
const _temp134 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/ShadowBomb.asset");
const _temp135 = require("../../../ring-of-titans/Assets/Data/Spells/Kanna/Smite.asset");

const _temp136 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/ActivatePortal.asset");
const _temp137 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/BloodCurse.asset");
const _temp138 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/CastedFear.asset");
const _temp139 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/CorrosiveDecay.asset");
const _temp140 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/CorruptedSoul.asset");
const _temp141 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/DemonGrasp.asset");
const _temp142 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/DemonSkull.asset");
const _temp143 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/DrainLife.asset");
const _temp144 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/EmpoweredSoulEssence.asset");
const _temp145 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/FireExplosion.asset");
const _temp146 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/InstantShadowlance.asset");
const _temp147 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/LimbnumbingCurse.asset");
const _temp148 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/OpenPortal.asset");
const _temp149 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/Shadowflame.asset");
const _temp150 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/Shadowlance.asset");
const _temp151 = require("../../../ring-of-titans/Assets/Data/Spells/Malrah/WeakenedSoulCurse.asset");

const _temp152 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/CycloneShot.asset");
const _temp153 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/ExplosiveShot.asset");
const _temp154 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/HookShot.asset");
const _temp155 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/MarkedForDeath.asset");
const _temp156 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/Multishot.asset");
const _temp157 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/Net.asset");
const _temp158 = require("../../../ring-of-titans/Assets/Data/Spells/MarksmanOrin/ShortDraw.asset");

const _temp159 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/BasicShot.asset");
const _temp160 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/DissociativeForce.asset");
const _temp161 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/EarthShield.asset");
const _temp162 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/EarthShieldCancel.asset");
const _temp163 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/Flare.asset");
const _temp164 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/FreezingTrap.asset");
const _temp165 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/InstantLongDraw.asset");
const _temp166 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/KillShot.asset");
const _temp167 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/LongDraw.asset");
const _temp168 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/MagicArrow.asset");
const _temp169 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/ParalyticArrow.asset");
const _temp170 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/PoisonTippedArrow.asset");
const _temp171 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/RapidFire.asset");
const _temp172 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/SedativeShot.asset");
const _temp173 = require("../../../ring-of-titans/Assets/Data/Spells/Orin/Volley.asset");

const _temp174 = require("../../../ring-of-titans/Assets/Data/Spells/Regional/BigJump.asset");
const _temp175 = require("../../../ring-of-titans/Assets/Data/Spells/Regional/Sit.asset");
const _temp176 = require("../../../ring-of-titans/Assets/Data/Spells/Regional/SpinWheel.asset");
const _temp177 = require("../../../ring-of-titans/Assets/Data/Spells/Regional/SpinWheelSpecialSpell.asset");

const _temp178 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/HammerStorm.asset");
const _temp179 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/HammerSwing.asset");
const _temp180 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/RequisiteConfession.asset");
const _temp181 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/RighteousHammer.asset");
const _temp182 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/RighteousHindrance.asset");
const _temp183 = require("../../../ring-of-titans/Assets/Data/Spells/SmiterAnthriel/SpikeWave.asset");

const _temp184 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialBuffDamageReduceSpell.asset");
const _temp185 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialBuffDamageSpell.asset");
const _temp186 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialBuffHealingReduceSpell.asset");
const _temp187 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialEyeSpell.asset");
const _temp188 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialFlagSpell.asset");
const _temp189 = require("../../../ring-of-titans/Assets/Data/Spells/Special/SpecialTeleportSpell.asset");

const _temp190 = require("../../../ring-of-titans/Assets/Data/Spells/StormcallerAirion/LightningStorm.asset");
const _temp191 = require("../../../ring-of-titans/Assets/Data/Spells/StormcallerAirion/StormCloud.asset");
const _temp192 = require("../../../ring-of-titans/Assets/Data/Spells/StormcallerAirion/StormWave.asset");
const _temp193 = require("../../../ring-of-titans/Assets/Data/Spells/StormcallerAirion/Thorns.asset");

const _temp194 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/AnkleSlice.asset");
const _temp195 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/BladeVortex.asset");
const _temp196 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/BladeVortexCancel.asset");
const _temp197 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/BladeVortexShortCD.asset");
const _temp198 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/Bleed.asset");
const _temp199 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/DevastatingStrike.asset");
const _temp200 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/Execute.asset");
const _temp201 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/FocusedRegeneration.asset");
const _temp202 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/IntimidatingShout.asset");
const _temp203 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/MenacingBlow.asset");
const _temp204 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/OverwhelmingPower.asset");
const _temp205 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/PowerStrike.asset");
const _temp206 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/PowerStrikeLongCD.asset");
const _temp207 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/Reflect.asset");
const _temp208 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/ShieldWall.asset");
const _temp209 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/ShieldWallCancel.asset");
const _temp210 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/SweepingRevenge.asset");
const _temp211 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/SwiftJustice.asset");
const _temp212 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/SwiftJusticeNoCD.asset");
const _temp213 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/TerrifyingShout.asset");
const _temp214 = require("../../../ring-of-titans/Assets/Data/Spells/Tarcza/ThroatKick.asset");

const _temp215 = require("../../../ring-of-titans/Assets/Data/Spells/Test/TestLuaSpell.asset");

const _temp216 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/BloodShackles.asset");
const _temp217 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/BlowFromTheShadows.asset");
const _temp218 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/DirtyTricks.asset");
const _temp219 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Focus.asset");
const _temp220 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Incapacitate.asset");
const _temp221 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Invigorate.asset");
const _temp222 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Kick.asset");
const _temp223 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/KnifeStorm.asset");
const _temp224 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/PoisonBlade.asset");
const _temp225 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/PoisonInABottle.asset");
const _temp226 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/PoisonStrike.asset");
const _temp227 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Rupture.asset");
const _temp228 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowBlink.asset");
const _temp229 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowBlinkNoCD.asset");
const _temp230 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowBlow.asset");
const _temp231 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowElusion.asset");
const _temp232 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowStrike.asset");
const _temp233 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowStrikeSecondHit.asset");
const _temp234 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/ShadowStrikeStealth.asset");
const _temp235 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Shank.asset");
const _temp236 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/SmokeBomb.asset");
const _temp237 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/SmokeScreen.asset");
const _temp238 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/Stealth.asset");
const _temp239 = require("../../../ring-of-titans/Assets/Data/Spells/Volen/StealthCancel.asset");

module.exports = {
  "TemplateSpellData": _temp0,
  "Airion":   {
    "Bash": _temp1,
    "CloudForm": _temp2,
    "CloudFormCancel": _temp3,
    "Cultivation": _temp4,
    "DustStorm": _temp5,
    "LightningBolt": _temp6,
    "LightningFire": _temp7,
    "LightningForm": _temp8,
    "MassRevitalization": _temp9,
    "NatureForm": _temp10,
    "RefreshingLeaves": _temp11,
    "Regeneration": _temp12,
    "Revitalize": _temp13,
    "Root": _temp14,
    "SwiftNourishment": _temp15,
    "SwiftRenewal": _temp16,
    "ThickSkin": _temp17,
    "ThunderFire": _temp18
  },
  "Anthriel":   {
    "BlindingLight": _temp19,
    "CorporealProtection": _temp20,
    "DivineCalling": _temp21,
    "HolyDeflection": _temp22,
    "HolyDeflectionShortCD": _temp23,
    "HolyStrike": _temp24,
    "Hope": _temp25,
    "InstantMysticalBrilliance": _temp26,
    "InstantRighteousCleansing": _temp27,
    "MaceSwing": _temp28,
    "MysticalBrilliance": _temp29,
    "RighteousCleansing": _temp30,
    "RighteousDefense": _temp31,
    "RighteousDefenseCancel": _temp32,
    "RighteousFlash": _temp33,
    "RighteousPrivilege": _temp34,
    "SpiritualBrilliance": _temp35,
    "VirtuousBonding": _temp36
  },
  "Azora":   {
    "ChainsOfIce": _temp37,
    "CrystalDetonation": _temp38,
    "FieryInfliction": _temp39,
    "Frostlance": _temp40,
    "FrostPortal": _temp41,
    "FrostShield": _temp42,
    "FrozenReversal": _temp43,
    "GlacialEncapsulation": _temp44,
    "GlacialEncapsulationCancel": _temp45,
    "IcyBindings": _temp46,
    "IcyExplosion": _temp47,
    "IcyShards": _temp48,
    "InnerFrost": _temp49,
    "InstantFrostlance": _temp50,
    "Invisibility": _temp51,
    "Penguinify": _temp52,
    "PenguinifyShort": _temp53,
    "Pyrolance": _temp54,
    "PyrolanceInstant": _temp55,
    "SharpIce": _temp56,
    "SlowFrostlance": _temp57,
    "Spellscatter": _temp58
  },
  "BlademasterTarcza":   {
    "InnerRage": _temp59,
    "Leap": _temp60,
    "Shockwave": _temp61,
    "ShockwaveShortCD": _temp62,
    "Slash1": _temp63,
    "Slash2": _temp64,
    "Slash3": _temp65,
    "Slice": _temp66
  },
  "BruiserVolen":   {
    "Dash": _temp67,
    "ScatteredAttacks": _temp68,
    "Slam": _temp69
  },
  "DarkKanna":   {
    "DarkDamage": _temp70,
    "DarkDot": _temp71,
    "DarkHeal": _temp72,
    "DarkMending": _temp73,
    "DarkShield": _temp74,
    "DarkSuppression": _temp75,
    "InstantHeal": _temp76,
    "InstantHealImproved": _temp77,
    "SoulSiphon": _temp78,
    "SoulSwap": _temp79,
    "SpiritSector": _temp80
  },
  "CrystalAzora":   {
    "CrystalBlast": _temp81,
    "CrystalBreath": _temp82,
    "CrystalHaste": _temp83,
    "CrystalShield": _temp84,
    "FireCrystal1": _temp85,
    "FireCrystal2": _temp86,
    "FireCrystal3": _temp87,
    "FireCrystal4": _temp88,
    "FrostCrystal1": _temp89,
    "FrostCrystal2": _temp90,
    "FrostCrystal3": _temp91,
    "FrostCrystal4": _temp92,
    "InstantPenguinify": _temp93,
    "MendingCrystals": _temp94,
    "PureCrystal1": _temp95,
    "PureCrystal2": _temp96,
    "PureCrystal3": _temp97,
    "PureCrystal4": _temp98
  },
  "EradicatorMalrah":   {
    "LongIncap": _temp99,
    "PestulantDevastation": _temp100,
    "PestulantDevastationImproved": _temp101,
    "ResidualImplosion": _temp102,
    "Shadowspike": _temp103,
    "VerdantPyre": _temp104,
    "VerdantPyreInstant": _temp105,
    "VirulentEruption": _temp106,
    "VirulentOrb": _temp107
  },
  "General":   {
    "Absorption": _temp108,
    "Bandage": _temp109,
    "ChainBreaker": _temp110,
    "Dismount": _temp111,
    "EmoteDance": _temp112,
    "EnhancedPower": _temp113,
    "EnhancedResilience": _temp114,
    "Jump": _temp115,
    "MasterofBattle": _temp116,
    "Meditation": _temp117,
    "Mount": _temp118
  },
  "Kanna":   {
    "BigHolySmite": _temp119,
    "BlessedLight": _temp120,
    "BlessedLightImp": _temp121,
    "Dispel": _temp122,
    "DivineIntervention": _temp123,
    "DivineLight": _temp124,
    "ExplosionOfLight": _temp125,
    "Fear": _temp126,
    "HolyFlash": _temp127,
    "HolyFortitude": _temp128,
    "PowerBurn": _temp129,
    "RighteousPrayer": _temp130,
    "RighteousShield": _temp131,
    "SacredSelection": _temp132,
    "ShackleOfLight": _temp133,
    "ShadowBomb": _temp134,
    "Smite": _temp135
  },
  "Malrah":   {
    "ActivatePortal": _temp136,
    "BloodCurse": _temp137,
    "CastedFear": _temp138,
    "CorrosiveDecay": _temp139,
    "CorruptedSoul": _temp140,
    "DemonGrasp": _temp141,
    "DemonSkull": _temp142,
    "DrainLife": _temp143,
    "EmpoweredSoulEssence": _temp144,
    "FireExplosion": _temp145,
    "InstantShadowlance": _temp146,
    "LimbnumbingCurse": _temp147,
    "OpenPortal": _temp148,
    "Shadowflame": _temp149,
    "Shadowlance": _temp150,
    "WeakenedSoulCurse": _temp151
  },
  "MarksmanOrin":   {
    "CycloneShot": _temp152,
    "ExplosiveShot": _temp153,
    "HookShot": _temp154,
    "MarkedForDeath": _temp155,
    "Multishot": _temp156,
    "Net": _temp157,
    "ShortDraw": _temp158
  },
  "Orin":   {
    "BasicShot": _temp159,
    "DissociativeForce": _temp160,
    "EarthShield": _temp161,
    "EarthShieldCancel": _temp162,
    "Flare": _temp163,
    "FreezingTrap": _temp164,
    "InstantLongDraw": _temp165,
    "KillShot": _temp166,
    "LongDraw": _temp167,
    "MagicArrow": _temp168,
    "ParalyticArrow": _temp169,
    "PoisonTippedArrow": _temp170,
    "RapidFire": _temp171,
    "SedativeShot": _temp172,
    "Volley": _temp173
  },
  "Regional":   {
    "BigJump": _temp174,
    "Sit": _temp175,
    "SpinWheel": _temp176,
    "SpinWheelSpecialSpell": _temp177
  },
  "SmiterAnthriel":   {
    "HammerStorm": _temp178,
    "HammerSwing": _temp179,
    "RequisiteConfession": _temp180,
    "RighteousHammer": _temp181,
    "RighteousHindrance": _temp182,
    "SpikeWave": _temp183
  },
  "Special":   {
    "SpecialBuffDamageReduceSpell": _temp184,
    "SpecialBuffDamageSpell": _temp185,
    "SpecialBuffHealingReduceSpell": _temp186,
    "SpecialEyeSpell": _temp187,
    "SpecialFlagSpell": _temp188,
    "SpecialTeleportSpell": _temp189
  },
  "StormcallerAirion":   {
    "LightningStorm": _temp190,
    "StormCloud": _temp191,
    "StormWave": _temp192,
    "Thorns": _temp193
  },
  "Tarcza":   {
    "AnkleSlice": _temp194,
    "BladeVortex": _temp195,
    "BladeVortexCancel": _temp196,
    "BladeVortexShortCD": _temp197,
    "Bleed": _temp198,
    "DevastatingStrike": _temp199,
    "Execute": _temp200,
    "FocusedRegeneration": _temp201,
    "IntimidatingShout": _temp202,
    "MenacingBlow": _temp203,
    "OverwhelmingPower": _temp204,
    "PowerStrike": _temp205,
    "PowerStrikeLongCD": _temp206,
    "Reflect": _temp207,
    "ShieldWall": _temp208,
    "ShieldWallCancel": _temp209,
    "SweepingRevenge": _temp210,
    "SwiftJustice": _temp211,
    "SwiftJusticeNoCD": _temp212,
    "TerrifyingShout": _temp213,
    "ThroatKick": _temp214
  },
  "Test":   {
    "TestLuaSpell": _temp215
  },
  "Volen":   {
    "BloodShackles": _temp216,
    "BlowFromTheShadows": _temp217,
    "DirtyTricks": _temp218,
    "Focus": _temp219,
    "Incapacitate": _temp220,
    "Invigorate": _temp221,
    "Kick": _temp222,
    "KnifeStorm": _temp223,
    "PoisonBlade": _temp224,
    "PoisonInABottle": _temp225,
    "PoisonStrike": _temp226,
    "Rupture": _temp227,
    "ShadowBlink": _temp228,
    "ShadowBlinkNoCD": _temp229,
    "ShadowBlow": _temp230,
    "ShadowElusion": _temp231,
    "ShadowStrike": _temp232,
    "ShadowStrikeSecondHit": _temp233,
    "ShadowStrikeStealth": _temp234,
    "Shank": _temp235,
    "SmokeBomb": _temp236,
    "SmokeScreen": _temp237,
    "Stealth": _temp238,
    "StealthCancel": _temp239
  }
}